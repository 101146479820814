const apiBase =
  process.env.NODE_ENV === "production"
    ? window.location.hostname
    : "http://localhost:8080";

const redirect_uri =
  process.env.NODE_ENV === "production"
    ? "https://" + window.location.hostname
    : "http://localhost:9000";

module.exports = {
    serverPort: 8080,
    appURL: apiBase,
    
    appName: "MYBE Timekeeper",
    appVersion: 1.6,
    appVersionMessage: "Re-work of calculation for consistency and accuracy.",

    redirect_url: redirect_uri,
    redirect_uri: redirect_uri
};